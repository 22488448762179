exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-hacked-js": () => import("./../../../src/pages/hacked.js" /* webpackChunkName: "component---src-pages-hacked-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-opdracht-1-js": () => import("./../../../src/pages/opdracht-1.js" /* webpackChunkName: "component---src-pages-opdracht-1-js" */),
  "component---src-pages-opdracht-2-js": () => import("./../../../src/pages/opdracht-2.js" /* webpackChunkName: "component---src-pages-opdracht-2-js" */),
  "component---src-pages-opdracht-3-js": () => import("./../../../src/pages/opdracht-3.js" /* webpackChunkName: "component---src-pages-opdracht-3-js" */),
  "component---src-pages-vraag-10-js": () => import("./../../../src/pages/vraag-10.js" /* webpackChunkName: "component---src-pages-vraag-10-js" */),
  "component---src-pages-vraag-11-js": () => import("./../../../src/pages/vraag-11.js" /* webpackChunkName: "component---src-pages-vraag-11-js" */),
  "component---src-pages-vraag-12-js": () => import("./../../../src/pages/vraag-12.js" /* webpackChunkName: "component---src-pages-vraag-12-js" */),
  "component---src-pages-vraag-2-js": () => import("./../../../src/pages/vraag-2.js" /* webpackChunkName: "component---src-pages-vraag-2-js" */),
  "component---src-pages-vraag-3-js": () => import("./../../../src/pages/vraag-3.js" /* webpackChunkName: "component---src-pages-vraag-3-js" */),
  "component---src-pages-vraag-4-js": () => import("./../../../src/pages/vraag-4.js" /* webpackChunkName: "component---src-pages-vraag-4-js" */),
  "component---src-pages-vraag-5-js": () => import("./../../../src/pages/vraag-5.js" /* webpackChunkName: "component---src-pages-vraag-5-js" */),
  "component---src-pages-vraag-6-js": () => import("./../../../src/pages/vraag-6.js" /* webpackChunkName: "component---src-pages-vraag-6-js" */),
  "component---src-pages-vraag-7-js": () => import("./../../../src/pages/vraag-7.js" /* webpackChunkName: "component---src-pages-vraag-7-js" */),
  "component---src-pages-vraag-8-js": () => import("./../../../src/pages/vraag-8.js" /* webpackChunkName: "component---src-pages-vraag-8-js" */),
  "component---src-pages-vraag-9-js": () => import("./../../../src/pages/vraag-9.js" /* webpackChunkName: "component---src-pages-vraag-9-js" */)
}

